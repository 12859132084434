<span class="mdc-tab__ripple"></span>

<div
  class="mat-mdc-tab-ripple"
  mat-ripple
  [matRippleTrigger]="elementRef.nativeElement"
  [matRippleDisabled]="rippleDisabled"></div>

<span class="mdc-tab__content">
  <span class="mdc-tab__text-label">
    <ng-content></ng-content>
  </span>
</span>

