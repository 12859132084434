export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyD_6fjI7Vsm4RQS6EJZSZ_an7Zehjz9YwQ",
    authDomain: "portugol-webstudio.firebaseapp.com",
    projectId: "portugol-webstudio",
    storageBucket: "portugol-webstudio.appspot.com",
    messagingSenderId: "845512624544",
    appId: "1:845512624544:web:b1d4787cafd265429dfcc5",
    measurementId: "G-BM3QGZS096",
  },
};
