<ng-template>
    <div class="help-modal__container" [attr.aria-labelledby]="'modal-' + title" role="dialog">
        <div class="{{className}}" [@enterAnimation] *ngIf="showing$ | async">
            <div class="title">
                <h3 id="modal-{{title}}" class="title__text">{{title}}</h3>
            </div>
            <div class="help-modal__body">
                <span *ngIf="!labels.length">
                    {{emptyMessage}}
                </span>
                <div>
                    <ul *ngFor="let label of labels" class="help-modal__list">
                        <h4 class="item-group-label">{{label}}</h4>
                        <ng-keyboard-shortcuts-help-item
                                *ngFor="let shortcut of shortcuts[label]; let i = index"
                                [shortcut]="shortcut"
                                [index]="i"
                        ></ng-keyboard-shortcuts-help-item>
                    </ul>
                </div>
            </div>
        </div>
        <div class="help-modal__backdrop" [@overlayAnimation] (mousedown)="hide()" *ngIf="showing$ | async"></div>
    </div>
</ng-template>
