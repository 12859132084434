<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<div id="preload">
  <ngx-monaco-editor></ngx-monaco-editor>
</div>

<mat-tab-group
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
  animationDuration="0"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="tab-icon">
        <svg-icon src="assets/lightbulb.svg" svgClass="text-primary cap-my-height" svgAriaLabel="Ícone do Portugol" />
      </span>

      <span class="tab-text">Portugol Webstudio</span>
    </ng-template>

    <app-tab-start (newTab)="addTab($event?.name, $event?.contents)" (help)="upsertHelpTab()"></app-tab-start>
  </mat-tab>

  <mat-tab *ngFor="let tab of tabs">
    <ng-template mat-tab-label>
      <span>
        <span class="tab-icon" [ngSwitch]="tab.type">
          <svg-icon *ngSwitchCase="'editor'" src="assets/mdi/file.svg" svgAriaLabel="Ícone de arquivo" />
          <svg-icon
            *ngSwitchCase="'help'"
            src="assets/mdi/help-circle-outline.svg"
            svgClass="text-quaternary"
            svgAriaLabel="Ícone de ajuda"
          />
        </span>

        <span class="tab-text">{{ tab.title }}</span>
      </span>

      <span class="tab-actions">
        <button
          mat-icon-button
          aria-label="Renomear aba"
          matTooltip="Renomear aba"
          (click)="changeTabTitle(tab)"
          *ngIf="tab.type === 'editor'"
        >
          <svg-icon src="assets/mdi/rename-outline.svg" svgAriaLabel="Ícone de renomear aba" />
        </button>

        <button
          mat-icon-button
          aria-label="Fechar aba"
          matTooltip="Fechar aba"
          (click)="closeTab(tab)"
          [style]="tab.type === 'editor' ? { marginLeft: 0 } : {}"
        >
          <svg-icon src="assets/mdi/close.svg" svgAriaLabel="Ícone de fechar aba" />
        </button>
      </span>
    </ng-template>

    <ng-container [ngSwitch]="tab.type">
      <app-tab-editor
        *ngSwitchCase="'editor'"
        [(title)]="tab.title"
        [code]="tab.contents"
        (help)="upsertHelpTab()"
      ></app-tab-editor>

      <app-tab-help *ngSwitchCase="'help'" (newTab)="addTab($event?.name, $event?.contents)"></app-tab-help>
    </ng-container>
  </mat-tab>

  <mat-tab disabled>
    <ng-template mat-tab-label class="tab-add">
      <button
        class="add-button"
        mat-icon-button
        aria-label="Nova aba de editor de código"
        matTooltip="Nova aba de editor de código"
        (click)="addTab()"
      >
        <svg-icon src="assets/mdi/plus.svg" svgAriaLabel="Ícone de adicionar aba" />
      </button>
    </ng-template>
  </mat-tab>
</mat-tab-group>
